<template>
  <div class="home">
    <template v-if="page === '/'">
      <Video :video="pageData.video" />
    </template>
    <div>
      <Splash :imgSrc="pageData.topSplash" :link="link" :page="page" />
      <template v-if="page === '/about-us'">
        <Splash :imgSrc="pageData.bottle"
          alt="We are storytellers. We write, we craft, we deliver. We make films for agencies, Brand and corporate. We have over 20 years of experience. We may be small but we are a creative powerhouse." />
        <Splash :imgSrc="pageData.studio" alt="The Studio Where stories are made" />
      </template>
      <template v-if="page === '/' || page === '/about-us'">
        <Car :page="page" :carousel="pageData.carousel" />
      </template>
      <template v-if="page === '/our-films'">
        <Films :films="pageData.films" />
      </template>
      <template v-if="page === '/tv-adverts' || page === '/case-study'">
        <Split :tvCase="pageData.sections" :page="page" />
        <Splash :imgSrc="pageData.secBanner" />
      </template>
      <template v-if="page === '/social'">
        <div class="socialBack" :style="'background-image: url(' + require('@/assets/social/Background.jpg') + ');'">
          <div @click="$bvModal.show(pageData.topFilm.id)" class="point">
          <Splash :imgSrc="pageData.padImg" :link="link" page="padding" />
          <Mod :film="pageData.topFilm" />
        </div>
          <SocialText />
          <div class="padSocial">
            <b-container>
              <Films :films="pageData.films" />
            </b-container>
          </div>
          <div class="padSocial">
            <Grid :gridPics="pageData.grid" />
          </div>
          <div class="padSocial">
            <p class="socialP">No footage gets left on the cutting room floor. <strong>"THIS IS THE WAY"</strong><b-img
                class="icon" :src="require('@/assets/social/Mando-Emoji.png')"></b-img></p>
          </div>
          <div class="padSocial"></div>
        </div>
      </template>
      <div @click="$bvModal.show(pageData.film.id)" class="point">
        <Splash :imgSrc="pageData.bottomSplash" />
        <Mod :film="pageData.film" />
      </div>
    </div>
  </div>
</template>

<script>
import Video from "@/components/video.vue";
import Splash from "@/components/splash.vue";
import Car from "@/components/car.vue";
import Films from "@/components/films.vue";
import Split from '@/components/split.vue'
import Mod from "@/components/modal.vue";
import SocialText from "@/components/socialText.vue";
import Grid from "@/components/grid.vue";
import assetsHome from "@/assets/assetsHome.js";
import assetsFilms from "@/assets/assetsFilms.js";
import assetsAbout from "@/assets/assetsAbout.js";
import assetsContact from "@/assets/assetsContact.js";
import assetsTV from "@/assets/assetsTV.js";
import assetsCase from "@/assets/assetsCase.js";
import assetsSocial from "@/assets/assetsSocial.js";
export default {
  name: "Home",
  components: { Video, Splash, Car, Films, Mod, Split, SocialText, Grid },
  computed: {
    page() {
      return this.$route.path;
    },
    pageData() {
      if (this.page === "/") {
        var data = assetsHome;
      } else if (this.page === "/our-films") {
        data = assetsFilms;
      } else if (this.page === "/about-us") {
        data = assetsAbout;
      } else if (this.page === "/contact-us") {
        data = assetsContact;
      } else if (this.page === "/tv-adverts") {
        data = assetsTV;
      } else if (this.page === "/case-study") {
        data = assetsCase;
      } else if (this.page === "/social") {
        data = assetsSocial;
      }
      return data[0];
    },
    link(linkRef) {
      if (this.page === "/") {
        linkRef = "#/our-films";
      } else {
        linkRef = "";
      }
      return linkRef;
    },
    bLink(linkRef) {
      if (this.page === "/" || this.page === "/about-us") {
        linkRef = "";
      } else {
        linkRef = "/";
      }
      return linkRef;
    },
  },
};
</script>

<style scoped>
.home {
  margin: -94px auto 0;
  max-width: 1920px;
}

li {
  list-style: none;
}

.pad {
  padding: 30px 0 20px;
}

.point {
  cursor: pointer;
}

.socialBack {
  background-repeat: repeat-y;
  background-size: contain;
  padding: 0 225px;
}

.icon {
  width: 40px;
  height: auto;
}

@media only screen and (max-width: 1366px) {
  .socialBack {
    padding: 0 125px;
  }
}

@media only screen and (max-width: 1200px) {
  .socialBack {
    padding: 0 75px;
  }
}

@media only screen and (max-width: 990px) {
  .home {
    margin-top: -1px;
  }

  .pad {
    padding: 0px 0 20px;
  }
}

@media only screen and (max-width: 500px) {
  .socialBack {

    padding: 0 50px;
  }
}</style>
