<template>
              <b-modal :id="film.id" hide-header centered hide-footer>
            <b-button class='noMod' @click="$bvModal.hide(film.id)">X</b-button>
            <div style="padding:42.19% 0 0 0;position:relative;"><iframe :src="film.vimeo" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script type="application/javascript" src="https://player.vimeo.com/api/player.js"></script>
          </b-modal>
</template>

<script>
export default {
    name:'modal',
    props:['film']
}
</script>

<style scoped>
::v-deep .modal-body{
  width: 100%;
  height: auto;
    padding:0;
}
::v-deep .modal-dialog{
  max-width: 100%;
  margin: 1.75rem 10.75rem;
}
::v-deep .modal-content{
  background-color: transparent;
  border: 0;
}
.noMod{
        float: right;
    margin-top: -50px;
    background: transparent;
    border: 0;
    font-size: 26px;
}
@media only screen and (max-width: 768px) {
::v-deep .modal-dialog{
  max-width: 100%;
  margin: 1.75rem 3.75rem;
}
}
@media only screen and (max-width: 480px) {
::v-deep .modal-dialog{
  max-width: 100%;
  margin: 1.75rem 0.75rem;
}
}
</style>