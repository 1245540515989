<template>
  <div>
    <b-row v-for="(section, index) in tvCase" :key="index">
      <b-col
        class="grid"
        lg="6"
        md="12"
        sm="12"
        :class="{
          'order-lg-0 order-md-0 order-sm-0': section.img_left === true,
          'order-lg-1 order-md-0 order-sm-0': section.img_left === false,
        }"
      >
        <b-img class="colImg" :src="imgLoc(section.img)" />
      </b-col>
      <b-col
        class="grid pad backImg"
        :style="'background-image:url(' + imgLoc(section.background) + ')'"
        lg="6"
        md="12"
        sm="12"
        :class="{
          'order-lg-0 order-md-1 order-sm-1': section.img_left === false,
          'order-lg-1 order-md-1 order-sm-1': section.img_left === true,
        }"
        ><div class="grid">
          <div
            :class="{ 'left blueTitle center': page === '/case-study', 'ad' : page === '/tv-adverts' }"
            v-for="(tSec, index) in section.sections"
            :key="index"
          >
            <h3 class="bold" :class="{ title: page === '/tv-adverts' }">{{ tSec.title }}</h3>
            <div
            class="reg"
              :class="{ text: page === '/tv-adverts' }"
              v-html="tSec.text"
            ></div>
          </div>
          <b-img
            class="logo center"
            :class="'logo'+index"
            v-if="section.logo"
            :src="imgLoc(section.logo)"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "split",
  props: ["tvCase", "page"],
  methods: {
    imgLoc(src) {
      if (this.page === "/case-study") {
        var imgSrc = require("@/assets/case/" + src);
      }
      if (this.page === "/tv-adverts") {
        imgSrc = require("@/assets/tv/" + src);
      }
      return imgSrc;
    },
  },
};
</script>

<style scoped>
.bold {
  letter-spacing: 8px;
}
.reg {
  letter-spacing: 4px;
}
.text{
  margin-top: -100px;
}
::v-deep .ad .reg p{
  font-weight: 600;
}
::v-deep .reg p{
  padding-bottom: 30px;
}
.grid {
  display: grid;
  padding: 0;
}
.pad {
  padding: 50px 80px;
}
.center {
  align-self: center;
}
.blueTitle:nth-child(3) h3{
  color: #0099cb;
}
::v-deep .blue {
  color: #0099cb;
}
.left {
  text-align: left;
  max-width: 85%;
}
.title {
  padding-top: 80px;
}

.colImg {
  width: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
}
.row {
  margin: 0;
}
.backImg {
  background-repeat: no-repeat;
  background-size: cover;
}
.logo {
  justify-self: center;
}
.logo0{
  width: 60%;
}
.logo1{
  width: 45%;
}
.logo2{
  width: 50%;
}
.ad{
display: grid;
}
@media only screen and (max-width: 1366px) {
  .title{
    padding-bottom: 75px;
  }
}
@media only screen and (max-width: 480px) {
  .title{
    padding-bottom: 75px;
    font-size: 1.5em;
  }
  .pad{
    padding: 50px 30px;
  }
  .text{
  margin-top: 0;
}
}
</style>
