<template>
    <div>
        <b-row v-for="(sec, index) in gridPics" :key="index">
            <b-col v-for="(pic, index) in sec.pics" :key="index" lg="3" md="3">
                <b-img class="gridP imgW" :src="require('@/assets/social/' + pic.img)"></b-img>
            </b-col>
        </b-row>
        <b-row>
            <b-col>

            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: ['gridPics']
}
</script>

<style>
.gridP {
    padding: 0 15px;
    margin: 15px 0;
}

@media only screen and (max-width: 990px) {
    .gridP {
        padding: 0 0px;

    }
}
</style>