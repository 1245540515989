<!-- Nav Menu -->

<template>
  <div id="app">
    <NavMenu />
    <transition name="fade" appear><router-view :key="$route.fullPath" /></transition>
    <Foot />
  </div>
</template>

<script>
import NavMenu from '@/components/navMenu.vue'
import Foot from '@/components/foot.vue'
export default {
  components: { NavMenu, Foot }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  text-align: center;
  font-family: "NetflixRegular";
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@font-face {
  font-family: "NetflixBlack";
  src: local("NetflixBlack"),
    url('./assets/fonts/NetflixSansBlack.ttf') format("truetype");
}

@font-face {
  font-family: "NetflixBold";
  src: local("NetflixBold"),
    url('./assets/fonts/NetflixSansBold.ttf') format("truetype");
}

@font-face {
  font-family: "NetflixIcon";
  src: local("NetflixIcon"),
    url('./assets/fonts/NetflixSansIcon.ttf') format("truetype");
}

@font-face {
  font-family: "NetflixLight";
  src: local("NetflixLight"),
    url('./assets/fonts/NetflixSansLight.ttf') format("truetype");
}

@font-face {
  font-family: "NetflixMedium";
  src: local("NetflixMedium"),
    url('./assets/fonts/NetflixSansMedium.ttf') format("truetype");
}

@font-face {
  font-family: "NetflixRegular";
  src: local("NetflixRegular"),
    url('./assets/fonts/NetflixSansRegular.ttf') format("truetype");
}

@font-face {
  font-family: "NetflixThin";
  src: local("NetflixThin"),
    url('./assets/fonts/NetflixSansThin.ttf') format("truetype");
}

.socialP {
  line-height: initial;
  font-size: 1.5rem;
}

.imgW {
  width: 100%;
}

.bold {
  font-family: "NetflixBold";
}

.padSocial {
  padding: 75px 0 0;
}

.reg {
  font-family: "NetflixRegular";
}

@media only screen and (max-width: 1440px) {
  .imgW {
    height: auto;
  }
}
@media only screen and (max-width: 500px) {
  .socialP {
  font-size: 1rem;
}
.padSocial {
  padding: 25px 0 0;
}
}
</style>
