const data = [
  {
    topSplash: "social/splash.jpg",
    padImg: "social/Play_Film_mage.jpg",
    studio: "",
    bottomSplash: "social/Bottom_Image.jpg",
    films: [
      {
        id: "1",
        title: "",
        subTitle: "",

        vimeo:
          "819459827",

        src: "Engage.jpg",
      },
      {
        id: "2",
        title: "",
        subTitle: "",

        vimeo:
          "https://player.vimeo.com/video/819459844?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Lightning_Sticks.jpg",
      },
      {
        id: "3",
        title: "",
        subTitle: "",

        vimeo:
          "https://player.vimeo.com/video/819459864?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Fist_bump.jpg",
      },
    ],
    topFilm: {
      id: "39",
      vimeo:
        "https://player.vimeo.com/video/578397386?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
    },
    film: {
      id: "50",
      vimeo:
        "https://player.vimeo.com/video/819459844?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",
    },
    grid: [
      {
        section: 1,
        pics: [
          {
            img: "ezgif.com-optimize_3.gif",
          },
          { img: "ezgif.com-optimize_2.gif" },
          { img: "ezgif.com-optimize_1.gif" },
          { img: "ezgif.com-optimize.gif" },
        ],
      },
      {
        section: 2,
        pics: [
          {
            img: "Square_4.jpg",
          },
          { img: "Square_2.jpg" },
          { img: "Square_3.jpg" },
          { img: "Square_1.jpg" },
        ],
      },
    ],
  },
];
export default data;
