const data = [
  {
    topSplash: "ourFilms/topImg/top.jpg",
    bottomSplash: "ourFilms/topImg/bottom-image.jpg",
    film: {
      id: "27",
      vimeo:
        "https://player.vimeo.com/video/375940138?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
    },
    films: [
      {
        id: "52",
        title: "SMOKESCREEN VISUALS",
        subTitle: "Reel 2023",

        vimeo:
          "https://player.vimeo.com/video/758668586?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Smokescreen_Reel.jpg",
      },
      {
        id: "51",
        title: "OWENS ENTERTAINMENT",
        subTitle: "The Not So Ordinary",

        vimeo:
          "https://player.vimeo.com/video/791099560?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Owens_Entertainment.jpg",
      },
      {
        id: "50",
        title: "CHUBB FIRE & SECURITY",
        subTitle: "New Website Launch",

        vimeo:
          "https://player.vimeo.com/video/766845423?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Chubb.jpg",
      },
      {
        id: "49",
        title: "BIGFOOT ARTS EDUCATION",
        subTitle: "Be a Cyber Smart-y",

        vimeo:
          "https://player.vimeo.com/video/798767487?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Be_A_Cyber Smart-y.jpg",
      },
      {
        id: "48",
        title: "CRANE & CO",
        subTitle: "The Sign",

        vimeo:
          "https://player.vimeo.com/video/755126448/?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "The_Sign.jpg",
      },
      {
        id: "47",
        title: "WORTH SCHOOL",
        subTitle: "Today was a Worth Day",

        vimeo:
          "https://player.vimeo.com/video/721050807?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Worth_School_Juniors Film.jpg",
      },
      {
        id: "46",
        title: "Bigfoot Arts Education Anti-Bullying Campaign",
        subTitle: "What Would You Do?",

        vimeo:
          "https://player.vimeo.com/video/762315113?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "What_Would_You_Do.jpg",
      },
      {
        id: "45",
        title: "Crane & Co",
        subTitle: "My Friend Mr Brooks",

        vimeo:
          "https://player.vimeo.com/video/704520655?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "My_Friend_Mr_Brooks.jpg",
      },
      {
        id: "44",
        title: "KEW GARDENS",
        subTitle: "Glow Wild Ready 2022",

        vimeo:
          "https://player.vimeo.com/video/528340072?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Glow_Wild_Getting_Ready.jpg",
      },
      {
        id: "43",
        title: "LOTUS LEARNING",
        subTitle: "Happiness Prescription",

        vimeo:
          "https://player.vimeo.com/video/758700186?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Lotus_Learning.jpg",
      },
      {
        id: "42",
        title: "Crane & Co",
        subTitle: "A New Home",

        vimeo:
          "https://player.vimeo.com/video/668627040?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "A_New_Home.jpg",
      },
      {
        id: "41",
        title: "Crane & Co",
        subTitle: "Just in time for Christmas",

        vimeo:
          "https://player.vimeo.com/video/662268443?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Time_For_Christmas.jpg",
      },
      {
        id: "40",
        title: "Worth School",
        subTitle: "My Weekend",

        vimeo:
          "https://player.vimeo.com/video/644337285?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",

        src: "Worth_School.jpg",
      },
      {
        id: "39",
        title: "Excalibur Games",
        subTitle: "Mach One Hotas",

        vimeo:
          "https://player.vimeo.com/video/578397386?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",

        src: "Excalibur_Games.jpg",
      },
      {
        id: "38",
        title: "Kew Gardens",
        subTitle: "Glowwild 2021 - Wakehurst Place",

        vimeo:
          "https://player.vimeo.com/video/528761832?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Glow_Wild.jpg",
      },
      {
        id: "37",
        title: "UNIVERSITY OF BRIGHTON & SUSSEX POLICE",
        subTitle: "What's In Your Pocket?",

        vimeo:
          "https://player.vimeo.com/video/489796147?h=20eb32ab8c&autoplay=1&title=0&byline=0&portrait=0",
        src: "Whats_in_your_pocket.jpg",
      },
      {
        id: "36",
        title: "Phileas Foggs World of adventures",
        subTitle: "World of Adventures",
        vimeo:
          "https://player.vimeo.com/video/292319218?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Foggs-Around-the-World.jpg",
      },
      {
        id: "35",
        title: "St Philips Catholic School",
        subTitle: "St Philips",

        vimeo:
          "https://player.vimeo.com/video/572016721?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",

        src: "St_Philips.jpg",
      },
      {
        id: "34",
        title: "Bewonder",
        subTitle: "The Beacon",

        vimeo:
          "https://player.vimeo.com/video/546490207?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",

        src: "The_Beacon.jpg",
      },
      {
        id: "33",
        title: "NHS",
        subTitle: "In Support For Our NHS",

        vimeo:
          "https://player.vimeo.com/video/416290331?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",

        src: "NHS.jpg",
      },
      {
        id: "32",
        title: "NHS - JCR",
        subTitle: "Breast Cancer Awareness",
        vimeo:
          "https://player.vimeo.com/video/595300096?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Running-for-Joy.jpg",
      },
      {
        id: "31",
        title: "Hailsham Roadways",
        subTitle: "Paving The Way - Series",
        vimeo:
          "https://player.vimeo.com/video/595304033?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",

        src: "Hailsham-Roadways.jpg",
      },
      {
        id: "29",
        title: "LEGAL & GENERAL",
        subTitle: "Grosvenor Shopping North Hampton",

        vimeo:
          "https://player.vimeo.com/video/540974452?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",

        src: "The_Grosvenor.jpg",
      },
      {
        id: "27",
        title: "Waldolf Education",
        subTitle: "The Brighton Waldorf School",

        vimeo:
          "https://player.vimeo.com/video/375940138?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",

        src: "Waldolf_School.jpg",
      },
      {
        id: "26",
        title: "Chestnut Tree House",
        subTitle: "Castle Night Trek 2022",
        vimeo:
          "https://player.vimeo.com/video/582463984?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Chest-Nut-Tree-Trekking.jpg",
      },
      {
        id: "25",
        title: "Foreign & Commonwealth Office",
        subTitle: "The British Embassy - Poland",
        vimeo:
          "https://player.vimeo.com/video/595291728?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "commonwealth-office.jpg",
      },
      {
        id: "24",
        title: "CU Phosco Lighting",
        subTitle: "Manufacturing across the UK",
        vimeo:
          "https://player.vimeo.com/video/256596713?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "CU-Phosco-Lighting.jpg",
      },
      {
        id: "22",
        title: "Bigfoot Arts Education",
        subTitle: "Back to School",
        vimeo:
          "https://player.vimeo.com/video/595296348?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Bigfoot-School-Education.jpg",
      },
      {
        id: "21",
        title: "Eastbourne College",
        subTitle: "Choose Your Sport",
        vimeo:
          "https://player.vimeo.com/video/323784562?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Eastbourne-College-Sports.jpg",
      },
      {
        id: "20",
        title: "Eastbourne College",
        subTitle: "Weekends at Eastbourne College",
        vimeo:
          "https://player.vimeo.com/video/340932492?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Eastbourne-College-Weekends.jpg",
      },
      {
        id: "19",
        title: "County Clean",
        subTitle: "The County Clean Group",
        vimeo:
          "https://player.vimeo.com/video/255700722?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "County-Clean.jpg",
      },
      {
        id: "18",
        title: "Super Bike Rentals",
        subTitle: "The Choice is Yours",
        vimeo:
          "https://player.vimeo.com/video/194960132?h=8d6de39443&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Super-Bike-Rentals.jpg",
      },
      {
        id: "17",
        title: "CRANE & CO",
        subTitle: "Hailsham and Beyond",
        vimeo:
          "https://player.vimeo.com/video/176725492?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Hailsham-and-Beyond.jpg",
      },
      {
        id: "16",
        title: "Channel 4 Shorts",
        subTitle: "Talk with God",
        vimeo:
          "https://player.vimeo.com/video/298258162?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Talk-with-God.jpg",
      },
      {
        id: "15",
        title: "Eastbourne County Council",
        subTitle: "Devonshire Quarter",
        vimeo:
          "https://player.vimeo.com/video/295551516?h=32b84b03ba&autoplay=1&&title=0&byline=0&portrait=0",
        src: "DQ-Filml.jpg",
      },
      {
        id: "14",
        title: "FI Management",
        subTitle: "Real Estate",
        vimeo:
          "https://player.vimeo.com/video/456939518?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "FI-Real-Estate.jpg",
      },
      {
        id: "13",
        title: "Kimberley Clark",
        subTitle: "Budapest Conference",
        vimeo:
          "https://player.vimeo.com/video/595810924?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",

        src: "Kimberley-Clark-1-A.jpg",
      },
      {
        id: "12",
        title: "Bewonder",
        subTitle: "Nordic Experience",
        vimeo:
          "https://player.vimeo.com/video/596452285?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Nordic-Experience.jpg",
      },

      {
        id: "11",
        title: "PHILEAS FOGGS WORLD OF ADVENTURES",
        subTitle: "Christmas Adventure",
        vimeo:
          "https://player.vimeo.com/video/372606098?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Foggs-Christmas-Adventure.jpg",
      },
      {
        id: "10",
        title: "KEW GARDENS",
        subTitle: "Glow Wild 2020 - Wakehurt Place",
        vimeo:
          "https://player.vimeo.com/video/199838946?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Glow-Wild-2020.jpg",
      },
      {
        id: "9",
        title: "University of Brighton",
        subTitle: "Students Journeys",
        vimeo:
          "https://player.vimeo.com/video/354870852?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "University-of-Brighton.jpg",
      },
      {
        id: "8",
        title: "Bede's",
        subTitle: "Time to Dance",
        vimeo:
          "https://player.vimeo.com/video/595305645?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Time-to-Dance.jpg",
      },
      {
        id: "7",
        title: "Chestnut Tree House",
        subTitle: "Littlehampton 10K",
        vimeo:
          "https://player.vimeo.com/video/246940026?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Chest-Nut-Tree-Marathon.jpg",
      },
      {
        id: "6",
        title: "Kew Gardens",
        subTitle: "Grow - Wakehurst Place",
        vimeo:
          "https://player.vimeo.com/video/199838946?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Kew-Gardens-Grow.jpg",
      },
      {
        id: "5",
        title: "Kikkoman",
        subTitle: "Kikkoman Masters",
        vimeo:
          "https://player.vimeo.com/video/365707304?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Kikkoman.jpg",
      },
      {
        id: "2",
        title: "EAST SUSSEX COUNTY COUNCIL",
        subTitle: "Children In Care",
        vimeo:
          "https://player.vimeo.com/video/213055139?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "Children-in-Care.jpg",
      },
      {
        id: "1",
        title: "University of Brighton",
        subTitle: "International Student Program",
        vimeo:
          "https://player.vimeo.com/video/210753591?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
        src: "University-of-Brighton-Abroad.jpg",
      },
      {
        id: "0",
        title: "",
        subTitle: "",
        vimeo: "",
        src: "Hello_There.jpg",
      },
    ],
  },
];
export default data;
