<template>
  <div class="vid">
    <div style="padding:56.25% 0 0 0;position:relative;">
      <iframe
      aria-label="Smokescreen Visuals Showreel"
        :src="video[0].vimeo"
        style="position:absolute;top:0;left:0;width:100%;height:100%;"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <script
      type="application/javascript"
      src="https://player.vimeo.com/api/player.js"
    ></script>
  </div>
</template>

<script>
export default {
  name: "video",
  props:['video'],
};
</script>

<style scoped>
.vid {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* align-content: center; */
  margin: auto;
  margin-bottom: -7px;
}
.home {
  margin-top: -56px;
}
li {
  list-style: none;
}
.imgSee {
  width: 65%;
  float: right;
  padding-top: 5px;
}
.marT {
  margin-top: -28px;
}
.row {
  margin-right: 0px;
}
.col {
  padding-right: 0px;
}
.splashLogo {
  position: absolute;
  top: 35%;
  width: 50%;
  left: 0%;
}
::v-deep .carousel-indicators {
  left: 55%;
}
video {
  max-width: 1920px;
}
@media only screen and (max-width: 768px) {
  .marT {
    margin-top: 0;
  }
  .imgSee {
    padding-top: 0px;
  }
  .splashLogo {
    display: none;
  }
}
</style>
