<template>
    <div id="social">
        <section class="padSocial">
            <div id="first">
                <h1 class="bold title large">
                    SOCIAL
                </h1>
                <h2 class="bold right">
                    FIRST
                </h2>
            </div>
            <div id="second">
                <p class="socialP">Our partners at Excalibur Games wanted a years worth of deliverables and that's exactly
                    what they got. Outstanding peripherals. Amazing content.</p>
            </div>
        </section>
        <section class="padSocial">
            <p class="socialP">We shot all the content over</p>
            <b-row>
                <b-col lg="6" md="6" cols="12">
                    <div id="third">
                        <h2 class="bold title">
                            1 LOCATION <br>
                            1 STUDIO <b-img class="icon" :src="require('@/assets/social/Joy-Stick-Emoji.png')"></b-img><span class="dots">...</span><b-img class="icon" :src="require('@/assets/social/Space-Invaders-Emoji.png')"></b-img><span class="dots">...</span>
                        </h2>
                    </div>
                </b-col>
                <b-col lg="6" md="6" cols="12">
                    <div id="forth">
                        <p class="socialP line">OVER <span class="bold title">50</span><br>
                            <span class="bold title">ASSETS</span> were made.
                        </p>
                    </div>
                </b-col>
            </b-row>
        </section>
        <section class="padSocial">
            <div id="fifth" class="tag">
                <p class="socialP">Hero/unboxing videos, social media ads, multi-language ads, gifs and photography</p>
            </div>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#social {
    text-align: left;
    position: relative;
    color: #000;
}

.title {
    letter-spacing: 4px;
    font-size: 6rem;
}

.large {
    font-size: 8rem;
}

.right {
    text-align: right;
    margin-top: -25px;
}

#first {
    max-width: 500px;
}

#second {
    padding-left: 450px;
}

.tag {
    text-align: center;
}
.icon{
    width: 40px;
    height: auto;
    margin-bottom: -25px;
}
.dots{
    font-size: 1rem;
}
.line{
    line-height: 1.2;
}
@media only screen and (max-width: 990px) {
    .title {
        font-size: 3rem;
    }

    .large {
        font-size: 5rem;
    }

    #first {
        max-width: 322px;
    }

    #second {
        padding-left: 255px;
    }
}

@media only screen and (max-width: 500px) {
    .title {
        font-size: 1.5rem;
    }
    .dots{
        font-size: .5rem;
    }
    .large {
        font-size: 3rem;
    }

    #first {
        max-width: 225px;
    }

    #second {
        padding-left: 50px;
    }

    #forth{
        padding-top: 25px;
    }
    .icon{
        margin-bottom: 0;
    }
    .socialP{
        font-size: .8rem;
    }
}</style>