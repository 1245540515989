const data = [
  {
    topSplash:'tv/TV_Adverts.jpg',
    bottomSplash:'tv/bottom.jpg',
    film:{
      id: "46",  
      vimeo:
        "https://player.vimeo.com/video/762315113?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",
    },
    secBanner:'tv/quote.jpg',
    sections: [

      {
        img_left: false,
        img: "TV_Adverts_image_1.jpg",
        background: "TV_Adverts_1_Background.jpg",
        sections: [
          {
            title: "IT'S TIME TO GET NOTICED",
            text:
              "<p><span class='blue'>ORIGINAL CONTENT</span> FOR YOUR BRAND.</p><p>REACHING THE RIGHT AUDIENCES.</p><p>DELIVERED NEXT TO THE BIGGEST<br/>TV SHOWS, MOVIES AND DOCUMENTARIES.</p><p>BUSINESSES OF ALL SHAPES AND SIZES</p><p>FROM <span class='blue'>SCRIPT TO SCREEN</span>.</p>",
          },
        ],
        logo: "",
      },
      {
        img_left: true,
        img: "TV_Adverts_image_2.jpg",
        background: "TV_Adverts_2_Background.jpg",
        sections: [
          {
            title: "THIS IS HOW WE DO IT",
            text:
              "<p><span class='blue'>TRUSTED PARTNERS</span> WITH ADSMART FROM SKY.</p><p>ACCESS TO MILLIONS OF SKY AND VIRGIN HOMES.</p><p>SELECTING YOUR IDEAL <span class='blue'>AUDIENCE</span> AND <span class='blue'>LOCATION</span>.</p><p>BROADCAST SAFE WITH CLEARCAST.</p><p><span class='blue'>AFFORDABLE AND ACCESSIBLE</span><br/>FOR ALMOST ANY BUSINESS.",
          },
        ],
        logo: "",
      },
      {
        img_left: false,
        img: "TV_Adverts_image_3.jpg",
        background: "TV_Adverts_3_Background.jpg",
        sections: [
          {
            title: "WHY IT WORKS",
            text:
              "<p>DEDICATED <span class='blue'>SCRIPT WRITING</span> WITH A FOCUS ON<br/>YOUR TARGET AUDIENCE.</p><p>TV IS TYPICALLY OVER <span class='blue'>TWICE AS EFFECTIVE</span> AS THE<br/>NEXT BEST PERFORMING MEDIUM.</p><p>TV IS RESPONSIBLE FOR <span class='blue'>71%</span> OF TOTAL<br/>ADVERTISING-GENERATED PROFIT.</p><p><span class='blue'>#1</span> MOST TRUSTED WAY TO ADVERTISE.</p><p>EVALUATION CAPABILITIES MEANS WE CAN MEASURE YOUR<br/>CAMPAIGN, SO YOU KNOW <span class='blue'>IT'S WORKING</span>.</p>",
          },
        ],
        logo: "",
      },
    ],
  },
];
export default data;
