const data = [
  {
    topSplash: "about/Smokescreen_Visuals_Logo.jpg",
    bottle:"about/Lightning_in_a_bottle.jpg",
    studio:'about/The-Studio.jpg',
    bottomSplash: "about/Black-History.jpg",
    film: {
      id: "50",
      vimeo:
        "https://player.vimeo.com/video/762195923?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",
    },
    carousel: [
      {
        src: "about/Slide_1.jpg",
      },
      {
        src: "about/Slide_2.jpg",
      },
      {
        src: "about/Slide_3.jpg",
      },
      {
        src: "about/Slide_4.jpg",
      },
    ],
  },
];
export default data;
