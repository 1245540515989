var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.tvCase),function(section,index){return _c('b-row',{key:index},[_c('b-col',{staticClass:"grid",class:{
        'order-lg-0 order-md-0 order-sm-0': section.img_left === true,
        'order-lg-1 order-md-0 order-sm-0': section.img_left === false,
      },attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-img',{staticClass:"colImg",attrs:{"src":_vm.imgLoc(section.img)}})],1),_c('b-col',{staticClass:"grid pad backImg",class:{
        'order-lg-0 order-md-1 order-sm-1': section.img_left === false,
        'order-lg-1 order-md-1 order-sm-1': section.img_left === true,
      },style:('background-image:url(' + _vm.imgLoc(section.background) + ')'),attrs:{"lg":"6","md":"12","sm":"12"}},[_c('div',{staticClass:"grid"},[_vm._l((section.sections),function(tSec,index){return _c('div',{key:index,class:{ 'left blueTitle center': _vm.page === '/case-study', 'ad' : _vm.page === '/tv-adverts' }},[_c('h3',{staticClass:"bold",class:{ title: _vm.page === '/tv-adverts' }},[_vm._v(_vm._s(tSec.title))]),_c('div',{staticClass:"reg",class:{ text: _vm.page === '/tv-adverts' },domProps:{"innerHTML":_vm._s(tSec.text)}})])}),(section.logo)?_c('b-img',{staticClass:"logo center",class:'logo'+index,attrs:{"src":_vm.imgLoc(section.logo)}}):_vm._e()],2)])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }