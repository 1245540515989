import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/tv-adverts",
    name: "tv-adverts",
    component: Home
  },
  {
    path: "/our-films",
    name: "our-films",
    component: Home
  },
  {
    path: "/case-study",
    name: "case-study",
    component: Home
  },
  {
    path: "/about-us",
    name: "about-us",
    component: Home
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: Home
  },
  {
    path: "/about-us",
    name: "about-us",
    component: Home
  },
  {
    path: "/social",
    name: "social",
    component: Home
  },

];

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default router;
