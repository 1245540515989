<template>
  <div class="backroundColor">
    <b-container>
      <b-row>
        <b-col lg="4" md="6" cols="12" class="social">
          <div class="sl">
            <a
            class="first"
              href="https://www.instagram.com/smokescreen_visuals/?hl=en"
              target="_blank"
            >
              <font-awesome-icon
                id="fab"
                class="twitterIcon"
                :icon="['fab', 'instagram']"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/smokescreen-visuals-ltd"
              target="_blank"
            >
              <font-awesome-icon
                id="fab"
                class="twitterIcon"
                :icon="['fab', 'linkedin']"
              />
            </a>
            <a
              href="https://www.facebook.com/SmokescreenVisuals/"
              target="_blank"
            >
              <font-awesome-icon
                id="fab"
                class="twitterIcon"
                :icon="['fab', 'facebook']"
              />
            </a>
          </div>
        </b-col>
        <b-col lg="4" md="6" cols="12">
          <p class="text reg left">
            01323 305020
            <br />
            <a
              href="mailto:info@smokescreenvisuals.co.uk"
              style="color: #2c3e50 !important"
            >
              info@smokescreenvisuals.co.uk
            </a>
          </p></b-col
        >
        <b-col lg="4" md="12" cols="12">
          <p class="text reg">
            Unit 11
            <br />
            Pacific House
            <br />
            Pacific Drive
            <br />
            Harbour Innovation Park
            <br />
            Eastbourne
            <br />
            BN23 6FA
          </p></b-col
        >
      </b-row>
      <b-row>
        <b-col>
          <div class="sl">
            <p class="cRight">
              © Smokescreen Visuals all rights reserved {{ year }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "footer",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.backroundColor {
  background-color: #fff;
  padding: 25px 0;
}
.text {
  color: #2c3e50;
  text-align: right;
}

.social a {
  float: left;
  color: #2c3e50;
  font-size: 25px;
  padding: 0 15px;
}
.left{
  text-align: left;
}
.cRight {
  padding-top: 20px;
  text-align: left;
}

@media only screen and (max-width: 990px) {
.text{
  text-align: left;
}
}

@media only screen and (max-width: 480px) {
  .backroundColor {
    padding: 25px 0 70px;
  }
  .social > div > a {
    padding:15px 15px 30px 15px;
  }
  .first{
    padding-left: 0!important;
  }
}
</style>
