const data = [
  {
    video: [
      {
        id: "32",
        title: "Main Video",
        vimeo:
          "https://player.vimeo.com/video/595311783?h=ae65811cb2&autoplay=1&background=1&title=0&byline=0&portrait=0",
      },
    ],
    topSplash: 'home/Creative_Partners.jpg',
    bottomSplash: 'home/Owens.jpg',
    film: {
      id: "51",
      vimeo:
        "https://player.vimeo.com/video/791099560?h=657df2e744&autoplay=1&title=0&byline=0&portrait=0",
    },
    carousel: [
      {
        src: "home/Carousel_1.jpg",
      },
      {
        src: "home/Carousel_2.jpg",
      },
      {
        src: "home/Carousel_3.jpg",
      },
      {
        src: "home/Carousel_4.jpg",
      },
      {
        src: "home/Carousel_5.jpg",
      },
      {
        src: "home/Carousel_6.jpg",
      },
    ],
  },
];
export default data;
