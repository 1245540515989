<template>
  <div>
    <template v-if="page === '/' || page === '/about-us'">
      <div class="car">
        <div style="position:relative;">
          <template v-if="page === '/'">
            <p class="recent">
              RECENT WORK
            </p>
          </template>
          <b-carousel
            id="carousel-1"
            :interval="6000"
            indicators
            background="#000000"
            img-width="1024"
            img-height="480"
            no-hover-pause
          >
              <b-carousel-slide
                v-for="(film, index) in carousel"
                :key="index"
                :img-src="require('@/assets/' + film.src)"
              >
              </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "car",
  props: ["page", 'carousel'],
};
</script>

<style scoped>
::v-deep .carousel-indicators {
  left: 55%;
}
::v-deep .carousel-caption {
  width: 100%;
  height: 100%;
  left: 0;
}
.recent {
  font-family: "NetflixBold";
  position: absolute;
  top: 15px;
  left: 25px;
  font-size: 18px;
  color: #fff;
  z-index: 200;
}
::v-deep .carousel-indicators li {
  height: 6px;
}
</style>
