<template>
  <div style="position:relative;" :class="{ 'padFilm': page === '/our-films', 'padSocial': page === 'padding' }">
    <a :href="link" :class="{ dead: link === '' }">
      <b-img class="imgW" :src="require('@/assets/' + imgSrc)"> </b-img>
      <template v-if="page === '/contact-us'">
        <div class="contactInfo">
          <b-row>
            <b-col>
              <div>
                <h1 class="title bold">GENERAL ENQUIRES</h1>
                <p class="subT reg">
                  <a class="email" href="mailto:info@smokescreenvisuals.co.uk"
                    >info@smokescreenvisuals.co.uk</a
                  >
                </p>
                <div class="space"></div>
                <h1 class="title bold">MATT DERBYSHIRE</h1>
                <h2 class="subT reg">Company Director</h2>
                <p class="subT reg">
                  <a class="email" href="mailto:matt@smokescreenvisuals.co.uk"
                    >matt@smokescreenvisuals.co.uk</a
                  >
                </p>
                <div class="space"></div>
                <h1 class="title bold">KAI ZAMMIT</h1>
                <h2 class="subT reg">Creative Director</h2>
                <p class="subT reg">
                  <a class="email" href="mailto:kai@smokescreenvisuals.co.uk"
                    >kai@smokescreenvisuals.co.uk</a
                  >
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
    </a>
  </div>
</template>

<script>
export default {
  name: "Splash",
  props: ["link", "imgSrc", "page", 'modal', 'alt'],
};
</script>

<style scoped>
.title {
  font-size: 1.5rem;
  margin-bottom: 0;
}
.subT {
  font-size: 1.2rem;
}
.contactInfo {
  position: absolute;
  right: 18.2%;
  top: 21.5%;
  color: #fff;
  text-align: left;
}
.space {
  padding: 12.3%;
}
a {
  color: #fff !important;
}
.padFilm {
  padding-bottom: 30px;
}
.dead {
  pointer-events: none;
  cursor: default;
}
.email{
  pointer-events: all;
  cursor: default;
}

@media only screen and (max-width: 1440px) {
  .space {
    padding: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .space {
    padding: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .space {
    display: none;
  }
  .contactInfo {
    right: 6.2%;
    top: 8.5%;
  }
  .subT {
    font-size: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .subT {
    font-size: 70%;
    margin-bottom: 0.5rem;
  }
  .title {
    font-size: 70%;
  }
}
</style>
