<template>
  <div class="sideP" :class="{'film' : $route.path === '/social'}">
    <b-row>
      <b-col class="mobileP" :lg="sizes[0].lg" :md="sizes[0].md" cols="12" v-for="(film, index) in films" :key="index"
        :class="{ 'point': film.id === '0' }">
        <template v-if="$route.path === '/our-films'">
          <b-card :id="film.id" :title="film.title" :sub-title="film.subTitle"
            :img-src="require('@/assets/ourFilms/posters/' + film.src)" img-alt="Image" img-top @click="noClick(film.id)">
          </b-card>
        </template>
        <template v-if="$route.path === '/social'">
          <b-container>
            <b-img :id="'poster-' + film.id" class="poster" :src="require('@/assets/ourFilms/posters/' + film.src)"
              @click="play(film.id)"></b-img>
            <div :id="'film-' + film.id" class="hideFilm">
              <vimeo-player :ref="'player' + film.id" player-height="450" player-width="330" :video-id="film.vimeo"
                @ready="onReady" />
            </div>
          </b-container>


        </template>
        <Modal :film="film" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Modal from "@/components/modal.vue";
export default {
  name: "ourFilms",
  props: ['films'],
  components: { Modal },
  data() {
    return {
      playOne: false
    }
  },
  methods: {
    noClick(id) {
      if (id > 0) {
        this.$bvModal.show(id)
      }
      else {
        null
      }
    },
    onReady() {
      this.playerReady = true
    },
    play(id) {
      if (id === '1') {
        document.querySelector('#poster-1').classList.add('hide');
        document.querySelector('#film-1').classList.add('showFilm');
        this.$refs.player1[0].play()
      }
      if (id === '2') {
        document.querySelector('#poster-2').classList.add('hide');
        document.querySelector('#film-2').classList.add('showFilm');
        this.$refs.player2[0].play()
      }
      if (id === '3') {
        document.querySelector('#poster-3').classList.add('hide');
        document.querySelector('#film-3').classList.add('showFilm');
        this.$refs.player3[0].play()
      }
    },
  },
  computed: {
    sizes() {
      if (this.$route.path === '/social') {
        var size = [{ lg: '4', md: '12' }]
      }
      else if (this.$route.path === '/our-films') {
        size = [{ lg: '2', md: '12' }]
      }
      return size
    }
  }
};
</script>

<style scoped>
.sideP {
  padding: 0 60px;
}

::v-deep .modal-body {
  width: 100%;
  height: auto;
  padding: 0;
}

::v-deep .modal-dialog {
  max-width: 100%;
}

::v-deep .card-title {
  font-family: "NetflixBold";
  text-transform: uppercase;
  text-align: left;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

::v-deep .card-subtitle {
  color: #2c3e50 !important;
  font-family: "NetflixRegular";
  text-align: left;
}

::v-deep .card-body {
  padding-left: 0;
  padding-top: 5px;
}

::v-deep .modal-content {
  background-color: transparent;
}

::v-deep .card {
  border: 0;
  cursor: pointer;
}

::v-deep .point>.card {
  cursor: default;
}

.poster {
  height: 450px;
  opacity: 1;
  margin-left: 39px;
  transition: all 2s ease-in-out;
}

.hide {
  opacity: 0;
}
.showFilm {
  position: absolute;
  top: 0;
  display: block !important;
  opacity: 0;
  transition: display 2s ease-in-out;
}

.hideFilm {
  position: absolute;
  top: 0;
  display: none;
  opacity: 1;
  transition: display 2s ease-in-out;
}

@media only screen and (max-width: 900px) {
  .film {
    margin: 0px 0px 30px -56px;
    padding: 0
  }
  .mobileP{
    padding-bottom: 15px;
  }
  .hideFilm{
    left: 25.6%;
  }
  .poster{
    margin-left: 0;
  }
}

@media only screen and (max-width: 1726px) {
.title{
  font-size: 3rem;
}
}

@media only screen and (max-width: 500px) {
  .film {
    margin: 0px 0px 30px -39px;
    padding: 0
  }
  .mobileP{
    padding-bottom: 15px;
  }
  .hideFilm{
    left: 26px;
  }
  .poster{
    margin-left: 25px;
  }
}
</style>
