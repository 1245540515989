<template>
  <b-navbar
    id="t"
    toggleable="lg"
    type="dark"
    :sticky="true"
    :class="[
      { 'navbar--hidden': !showNavbar },
      { bc: lastScrollPosition > 100 },
    ]"
  >
    <b-navbar-brand to="/" class="mobHide"
      ><b-img class="logo" :src="require('@/assets/about/Logo.png')" alt="Smokescreen Logo Link to home"></b-img
    ></b-navbar-brand>
    <b-navbar-brand to="/" class="mobShow"
      ><b-img class="logo" :src="require('@/assets/about/Logo.png')" alt="Smokescreen Logo Link to home"></b-img
    ></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item @click="feat" class="link reg">FEATURED</b-nav-item>
        <b-nav-item to="/social" class="link reg">SOCIAL</b-nav-item>
        <b-nav-item to="/tv-adverts" class="link reg">TV ADVERTS</b-nav-item>
        <b-nav-item to="/our-films" class="link reg">FILMS</b-nav-item>
        <b-nav-item to="/case-study" class="link reg">CASE STUDY</b-nav-item>
        <b-nav-item to="/about-us" class="link reg">ABOUT</b-nav-item>
        <b-nav-item to="/contact-us" class="link reg">CONTACT</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "menu",
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },
  methods: {
    feat() {
      if (this.$route.path === "/") {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        this.$router.push("/");
      }
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
::v-deep .link {
  color: #fff !important;
  padding: 9px 15px 0px;
  text-align: left;
}
::v-deep .nav-link:hover{
  text-decoration: underline;
}
::v-deep .link > a {
  color: #fff !important;
}
.logo {
  width: 300px;
  padding-top: 10px;
}
.mobShow {
  display: none;
}
.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}
.bc {
  background-color: black;
}
.navbar {
  padding: 0 1rem 0.5rem 1rem;
  max-width: 1920px;
  margin: auto;
  transition: ease-in-out 0.5s;
}
.router-link-active{
  text-decoration: underline!important;
}
@media only screen and (max-width: 990px) {
  .mobHide {
    display: none;
  }
  .mobShow {
    display: inline-block;
  }
  .navbar {
    background-color: black !important;
  }
}
@media only screen and (max-width: 480px) {
  .logo{
    width: 270px;
  }
}
</style>
