const data = [
  {
    topSplash: "contact/baked.jpg",
    bottomSplash: "contact/bottom-image.jpg",
    film: {
        id: "36",
        vimeo:
          "https://player.vimeo.com/video/292319218?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
      }
  },
];
export default data;