const data = [
  {
    topSplash: "case/Case_Study.jpg",
    bottomSplash: "case/bottom.jpg",
    film: {
      id: "50",
      vimeo:
        "https://player.vimeo.com/video/766845423?h=657df2e744&autoplay=1&&title=0&byline=0&portrait=0",
    },
    secBanner:'case/logos.jpg',
    sections: [
      {
        img_left: false,
        img: "Case_Study_Image_1.jpg",
        background: "Case_Study_1_Background.jpg",
        sections: [
          {
            title: "CHALLENGE",
            text:
              "<p>Our client is one of the most first-rate educators in the South East. It has a great reputation as a private school with a heart and soul. However, the intake was not as high as they would have liked in the Juniors. The school aslo wanted to achieve more reach with the children and parents from London.</p>",
          },
          {
            title: "SOLUTION",
            text:
              "<p>Smokescreen work directly with the client to write, film, direct and edit a short promo film for the school website. The film showcases a child's journey from London and then experiences of attending the school and the joy it brings children.</p>",
          },
          {
            title: "IMPACT",
            text:
              "<p>Intrest from 11-13year olds was up <span class='blue'>70%</span>, with keen interest from London & Oversea.</p>",
          },
        ],
        logo: "Worth_Logo.png",
      },
      {
        img_left: true,
        img: "Case_Study_Image_2.jpg",
        background: "Case_Study_2_Background.jpg",
        sections: [
          {
            title: "CHALLENGE",
            text:
              "<p>A new indoor adventure park wanted to reach more families with 0-17year olds and encourage older generations to enjoy the park. Brand awareness was key, the parks creators wanted to create a promotional film with 'Disney Magic' to encourage this.<p>",
          },
          {
            title: "SOLUTION",
            text:
              "<p>A 30 second advert was created for use within the catchment area of the attraction. Focusing on households of 0-17years. Smokescreen then wrote, cast filmed directed and edited a 90 second promotional film for their website and social media, with shorter versions for Sky. All aspects from the script, advertising campaign and delivery were covered</p>",
          },
          {
            title: "IMPACT",
            text:
              "<p>When the client was invited to approve the advert in our screening studio, they were rendered speechless and said <span class='blue'>'Now that's Disney!'</span> Aired as a campaign over 40 days initially, increasing awareness and patronage.",
          },
        ],
        logo: "Owens_Logo.png",
      },
      {
        img_left: false,
        img: "Case_Study_Image_3.jpg",
        background: "Case_Study_1_Background.jpg",
        sections: [
          {
            title: "CHALLENGE",
            text:
              "<p>Our client, an independent estate agent, needed the same presence as with the larger multi-national estate agent. The brand was about the client's story, giving them an exceptional buying/selling experience.</p>",
          },
          {
            title: "SOLUTION",
            text:
              "<p>Smokescreen wrote, cast, filmed, directed and edited a promotional advert that was show in Showcase Cinemas and social media platforms.</p>",
          },
          {
            title: "IMPACT",
            text:
              "<p>This enabled the client to encourage buying/selling at a difficult time in the market, after the short falls of COVID. Enforcing the brand through unique <span class='blue'>story telling</span>.</p>",
          },
        ],
        logo: "Crane_Logo.png",
      },
    ],
  },
];
export default data;
